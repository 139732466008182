import React from "react";
import styled from "@emotion/styled";
import { theme } from "../utils/global-styles/theme";
import useRegistrationSuccessData from "../hooks/registration-success-data";
import Layout from "../components/Layout/Layout";
import SuccessDisplay from "../components/shared/SuccessDisplay/SuccessDisplay";

const SectionContainer = styled.section`
    padding: 120px 328px;
    @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      padding: 2em 1em 3em 1em;
    }
  `,
  registrationSuccess = ({ data, pageContext: { locale } }) => {
    const {
        heading,
        shortDescription,
        contentModules,
      } = data?.registrationSuccessData?.nodes[0],
      redirectToLogin = () => {
        window.location.replace(`${process.env.GATSBY_login_url}sso`);
      };
    return (
      <Layout locale={locale}>
        <SectionContainer>
          <SuccessDisplay
            header={heading}
            subheader={shortDescription}
            buttonText={contentModules[0].linkText}
            handleSubmit={redirectToLogin}
          />
        </SectionContainer>
      </Layout>
    );
  };

export const query = graphql`
  query($locale: String!) {
    registrationSuccessData: allContentfulPageLayout(
      filter: {
        queryIdentifier: { eq: "reg-success" }
        node_locale: { eq: $locale }
      }
    ) {
      nodes {
        heading
        shortDescription
        contentModules {
          ... on ContentfulLink {
            linkText
          }
        }
      }
    }
  }
`;

export default registrationSuccess;
