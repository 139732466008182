import React from "react";
import styled from "@emotion/styled";
import { theme } from "../../../utils/global-styles/theme";
import PropTypes from "prop-types";

const SubheaderText = styled.p`
    font-family: ${theme.fontFaces.headers};
    margin-top: 10px;
    margin-bottom: 50px;
    font-size: 1.7em;
    line-height: 1.2em;
    @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      font-size: 1.5rem;
    }
  `,
  StyledButton = styled.button`
    padding: 1em 3em;
    border-width: 1px;
    border-style: solid;
    margin-right: 5em;
    cursor: pointer;
  `,
  ButtonText = styled.div`
    color: ${theme.colors.white};
    font-weight: bold;
    font-size: 1.5em;
  `,
  SuccessDisplay = ({ header, subheader, buttonText, handleSubmit }) => {
    return (
      <>
        {header && <h3>{header} </h3>}
        {subheader && <SubheaderText>{subheader}</SubheaderText>}
        <StyledButton
          className="primary-bg primary-border"
          onClick={() => handleSubmit()}
          onMouseDown={e => e.preventDefault()}
        >
          {buttonText && <ButtonText>{buttonText}</ButtonText>}
        </StyledButton>
      </>
    );
  };

SuccessDisplay.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  buttonText: PropTypes.string,
  handleSubmit: PropTypes.func,
};

export default SuccessDisplay;
